import Loader from "component/loader";
import { useAgency } from "context/agency";
import React from "react";

function ButtonGradient({
  type,
  disabled,
  label,
  processing,
  children,
  onClick,
  className,
}) {
  const { agency } = useAgency();
  const btn_bg = agency.location_config?.button_bg_color;
  const btn_text = agency.location_config?.button_text_color;

  return (
    <button
      style={{ backgroundColor: btn_bg, color: btn_text }}
      onClick={onClick}
      type={type || "button"}
      class={`${
        disabled ? "opacity-50 cursor-not-allowed" : ""
      } text-white ${className} bg-gradient-to-br ${
        btn_bg ? "" : "from-green-400 to-blue-600"
      } ${
        btn_text ? "" : "text-white"
      } hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
      disabled={disabled || processing}
    >
      {processing ? <Loader /> : label || children}
    </button>
  );
}

export default ButtonGradient;
