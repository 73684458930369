import React, { useEffect, useState } from 'react'
import TopHeader from './top-header'
import NavItem from './nav-item'
import NavDropdown from './nav-dropdown'
import AppLogo from './app-logo'
import HomeIcon from 'icon/home'
import ScanIcon from 'icon/scanIcon'
import SpeakerIcon from 'icon/speaker'
import ChartIcon from 'icon/chart'
import WebhookIcon from 'icon/webhookicon'
import BroadcastIcon from 'icon/broadcasticon'
import StarIcon from 'icon/staricon'
import CampaignsIcon from 'icon/campaigns'
import { PREMIUM_APPS } from 'enums'
import ChatIcon from 'icon/chatIcon'
import TicketIcon from 'icon/ticketIcon'
import { useAgency } from 'context/agency'
import GraphIcon from 'icon/graph'
import BasicinfoIcon from 'icon/basicinfo'
import TeamIcon from 'icon/team'
import LocationIcon from 'icon/locationicon'
import KeyIcon2 from 'icon/key'
import LinkIcon from 'icon/link'
import BillIcon from 'icon/bill'
import ButtonGradient from 'component/Buttons/dashboard-btn'
import SideNAvButtonGradient from 'component/Buttons/sidenav-btn'
import { useNavigate } from 'react-router-dom'
import GiftIcon from 'icon/gift'
import WalletIcon from 'icon/walleticon'
import SnapshotIcon from 'icon/SnapshotIcon'
import { useLocationInfo } from 'context/location'
import ChurchIcon from 'icon/church'
import IntegrationIcon from 'icon/integrationicon'
import NewEvent from 'icon/newevent'

const menu = [
  {
    label: 'Dashboard',
    path: './dashboard',
    icon: <GraphIcon />,
  },
  // {
  //   label: 'Campaigns',
  //   path: './campaigns',
  //   icon: <CampaignsIcon />,
  //   key: 'campaign',
  // },
  {
    label: 'Church',
    path: './church',
    icon: <ChurchIcon />,
    // key: 'church',
  },
  {
    label: 'Event',
    path: './event',
    icon: <NewEvent />,
    // key: 'church',
  },
  // {
  //   label: 'GrowthTool',
  //   path: './growthtool',
  //   icon: <ChartIcon />,
  // },
  {
    label: 'Customers',
    path: './customers',
    icon: <StarIcon />,
    key: 'customer',
  },

  {
    label: 'Scanner',
    path: './scanner',
    icon: <ScanIcon />,
    key: 'scanner',
  },
  {
    label: 'BroadCast',
    path: './broadcast',
    icon: <BroadcastIcon />,
  },
  // {
  //   label: 'Integration',
  //   path: './integration',
  //   icon: <IntegrationIcon className={"test"}/>,
  // },
  // {
  //   label: 'Webhook',
  //   path: './webhook',
  //   icon: <WebhookIcon />,
  // },
]

const settings = [
  {
    label: 'General Info',
    path: './settings/generalinfo',
    icon: <BasicinfoIcon />,
  },
  // {
  //   label: 'Billing',
  //   path: './settings/billings',
  //   icon: <BillIcon />,
  // },
  // {
  //   label: 'Geo Locations',
  //   path: './settings/geo-location',
  //   icon: <LocationIcon />,
  // },
  // {
  //   label: 'ApiKey',
  //   path: './settings/apikey',
  //   icon: <KeyIcon2 />,
  // },
  // {
  //   label: 'Integration',
  //   path: './settings/integration',
  //   icon: <LinkIcon />,
  // },
  // {
  //   label: 'Agency Info',
  //   path: './settings/agencyinfo',
  //   icon: <BasicinfoIcon />,
  // },
]

function Sidebar(className) {
  const { agency } = useAgency()
  const { saasPlan } = useLocationInfo()
  const [openSideMenu, setOpenSideMenu] = useState(false)
  const [showSettings, setShowSettings] = useState(
    window.location.pathname.includes('settings')
  )
  const [filteredMenu, setFilteredMenu] = useState(menu)
  const navigate = useNavigate()
  const sidenav_bg_color = '#0a4d85'
  const page_color = agency.location_config?.page_bg_color
  const s_text_color = agency.location_config?.sidenav_text_color

  const renderItem = (menu_item) => (
    <div key={menu_item.label}>
      {menu_item.children ? (
        <NavDropdown {...menu_item} />
      ) : (
        <NavItem
          {...menu_item}
          active={window.location.pathname === menu_item.path}
        />
      )}
    </div>
  )

  useEffect(() => {
    if (saasPlan?.features?.length) {
      const filtered = menu.filter((item) =>
        saasPlan.features.find((key) => key.includes(item.key))
      )
      setFilteredMenu(filtered)
    }
  }, [saasPlan])

  return (
    <div className=''>
      <nav
        style={{ backgroundColor: page_color }}
        className='pt-3 fixed w-full md:w-[calc(100%-329px)] top-0 z-50 left-0 md:left-[292px] '
      >
        <div className=' bg-white border-b border-gray-200 rounded-t-3xl'>
          <div className='px-3 py-3 flex items-center justify-between md:justify-end'>
            <button
              type='button'
              className='inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
              onClick={() => setOpenSideMenu(!openSideMenu)}
            >
              <span className='sr-only'>Open sidebar</span>
              <svg
                className='w-6 h-6'
                aria-hidden='true'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  clipRule='evenodd'
                  fillRule='evenodd'
                  d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
                />
              </svg>
            </button>
            <TopHeader />
          </div>
        </div>
      </nav>
      <aside
        style={{
          backgroundColor: sidenav_bg_color,
        }}
        className={` rounded-3xl h-[calc(100vh-24px)] mt-3 fixed top-0 md:left-3 z-40 w-64   transition-transform bg-white border- sm:translate-x-0  ${
          openSideMenu ? 'transform-none' : '-translate-x-full'
        }`}
      >
        <div className='min-h-[67px] flex flex-col justify-center rounded-t-3xl mb-2'>
          <AppLogo onToggle={() => setOpenSideMenu(!openSideMenu)} />
        </div>

        <div
          style={{
            height: `calc(100vh - ${showSettings ? '67px' : '120px'})`,
          }}
          className={`px-3 pb-4 overflow-y-auto ${
            sidenav_bg_color ? '' : 'bg-white'
          } `}
        >
          {showSettings && (
            <div class='my-3'>
              <SideNAvButtonGradient
                type='button'
                onClick={() => {
                  setShowSettings(false)
                  navigate('/')
                }}
                className={'w-full pb-2'}
              >
                Go Back
              </SideNAvButtonGradient>
            </div>
          )}
          <ul className='space-y-2 font-medium'>
            {(showSettings ? settings : filteredMenu).map((menu_item) =>
              renderItem(menu_item)
            )}
          </ul>
          {!showSettings && (
            <div class='absolute bottom-2 w-full px-4 -ml-3'>
              <SideNAvButtonGradient
                type='button'
                onClick={() => {
                  setShowSettings(true)
                  navigate('./settings/')
                }}
                className={'w-full pb-2'}
              >
                Settings
              </SideNAvButtonGradient>
            </div>
          )}
        </div>
      </aside>
    </div>
  )
}

export default Sidebar
