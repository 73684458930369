import React, { lazy } from 'react'
import ComponentLoader from './component-loader'

const RegisterPassFormCampaign = ComponentLoader(
  lazy(() => import('screens/public/register-new-pass'))
)

const Pass = ComponentLoader(lazy(() => import('screens/public/Pass')))

const PublicRoutes = [
  {
    path: 'registration',
    children: [
      {
        path: 'tool/:campaign_code',
        element: <RegisterPassFormCampaign />,
      },
    ],
  },
  {
    path: 'customer/pass/:pass_code',
    element: <Pass />,
  },
]

export default PublicRoutes
