import { useLocationInfo } from 'context/location'
import nslogo from '../../assets/images/ns-wallet-pass.png'


function AppLogo() {
  const { location } = useLocationInfo()
  
  // const alphabets = location?.name || location?.basic_info?.name || 'Location'
  // const slicedAlphabets = alphabets.slice(0, 12)
  // const dot = slicedAlphabets + '...'
  return (
    // <div className="px-3 flex items-center justify-start rtl:justify-end">
    //   <a href="/" className="flex ms-2 justify-start">
    //     <div className=''>
    //       <img
    //       src={nslogo}
    //       className="w-8 h-8 rounded-full object-cover me-3"
    //       alt="FlowBite Logo"
    //     />
    //     </div>
    //     <div
    //       className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap "
    //       style={{
    //         color: '#0a4d85',
    //       }}
    //     >
    //       {dot}
    //     </div>
    //   </a>
    // </div>
    <div className='flex items-center justify-start rtl:justify-end'>
      {/* <button
        type='button'
        className='inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
        onClick={onToggle}
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clipRule='evenodd'
            fillRule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          />
        </svg>
      </button> */}
      <div className='lg:block md:block hidden'>
        <a href='/' className='flex ms-2 md:me-24'>
          <img
            src={nslogo}
            className='w-8 h-8 rounded-full bg-white object-cover me-3'
            alt='FlowBite Logo'
          />
          <span
            // style={{
            //   color: '#0a4d85',
            // }}
            className='self-center w-36 text-xl truncate text-white font-semibold sm:text-2xl whitespace-nowrap'
            title={location?.name || 'Location'}
          >
            {location?.name || 'Location'}
          </span>
        </a>
      </div>
    </div>
  )
}

export default AppLogo
