import React, { useState, createContext, useContext, useEffect } from "react";
import { useAuth } from "./auth";
import env from "configuration";
import io from "socket.io-client";
import { useLocationInfo } from "./location";

const initChatConnectionContextPropsState = {
  socket: undefined,
};

const ChatConnectionContext = createContext(
  initChatConnectionContextPropsState
);

const useChatConnection = () => {
  return useContext(ChatConnectionContext);
};

const ChatConnectionProvider = ({ children }) => {
  const { auth } = useAuth();
  const { location } = useLocationInfo();
  const [socket, setSocket] = useState(null);

  const setupSocket = () => {
    const socketOptions = {
      query: { token: auth.token, account_id: location._id },
    };
    const socketIO = io(env.SERVER, socketOptions);
    socketIO.on("disconnect", () => {
      console.log("Socket Disconnected");
    });

    socketIO.on("connect", () => {
      console.log("Socket Connected");
    });
    setSocket(socketIO);
  };

  // const onLoad = () => {
  //   if (location) setupSocket()
  // }

  // useEffect(onLoad, [])

  return (
    <ChatConnectionContext.Provider
      value={{
        socket,
      }}
    >
      {children}
    </ChatConnectionContext.Provider>
  );
};

export { ChatConnectionProvider, useChatConnection };
