import Sidebar from 'component/Sidebar/sidebar'
import { ChatConnectionProvider } from 'context/chat-connection'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AdminLayout() {
  return (
    <ChatConnectionProvider>
        <Sidebar />
        <main className='sm:ml-[292px] md:w-[calc(100vw-329px)]'>
          <div className='pt-5 mt-[67px]'>
            <div
              style={{
                borderRadius: '0px 0px 20px 20px ',
                minHeight: 'calc(100vh - 100px)',
              }}
              className='p-4 bg-white'
            >
              <Outlet />
            </div>
          </div>
        </main>
    </ChatConnectionProvider>
  )
}

export default AdminLayout
